/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportVersionContentPreviewDTO } from './ReportVersionContentPreviewDTO';
import {
    ReportVersionContentPreviewDTOFromJSON,
    ReportVersionContentPreviewDTOFromJSONTyped,
    ReportVersionContentPreviewDTOToJSON,
} from './ReportVersionContentPreviewDTO';

/**
 * 
 * @export
 * @interface ReportVersionMetadataDTO
 */
export interface ReportVersionMetadataDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ReportVersionMetadataDTO
     */
    draft: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    readonly reportId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionAuthor: string;
    /**
     * 
     * @type {ReportVersionContentPreviewDTO}
     * @memberof ReportVersionMetadataDTO
     */
    versionContentPreview: ReportVersionContentPreviewDTO;
    /**
     * 
     * @type {Date}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionCreationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionId: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionLastModificationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionName?: string;
}

/**
 * Check if a given object implements the ReportVersionMetadataDTO interface.
 */
export function instanceOfReportVersionMetadataDTO(value: object): value is ReportVersionMetadataDTO {
    if (!('draft' in value) || value['draft'] === undefined) return false;
    if (!('reportId' in value) || value['reportId'] === undefined) return false;
    if (!('versionAuthor' in value) || value['versionAuthor'] === undefined) return false;
    if (!('versionContentPreview' in value) || value['versionContentPreview'] === undefined) return false;
    if (!('versionCreationTime' in value) || value['versionCreationTime'] === undefined) return false;
    if (!('versionId' in value) || value['versionId'] === undefined) return false;
    if (!('versionLastModificationTime' in value) || value['versionLastModificationTime'] === undefined) return false;
    return true;
}

export function ReportVersionMetadataDTOFromJSON(json: any): ReportVersionMetadataDTO {
    return ReportVersionMetadataDTOFromJSONTyped(json, false);
}

export function ReportVersionMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportVersionMetadataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'draft': json['draft'],
        'reportId': json['reportId'],
        'versionAuthor': json['versionAuthor'],
        'versionContentPreview': ReportVersionContentPreviewDTOFromJSON(json['versionContentPreview']),
        'versionCreationTime': (new Date(json['versionCreationTime'])),
        'versionDescription': json['versionDescription'] == null ? undefined : json['versionDescription'],
        'versionId': json['versionId'],
        'versionLastModificationTime': (new Date(json['versionLastModificationTime'])),
        'versionName': json['versionName'] == null ? undefined : json['versionName'],
    };
}

export function ReportVersionMetadataDTOToJSON(value?: Omit<ReportVersionMetadataDTO, 'reportId'|'versionCreationTime'|'versionId'|'versionLastModificationTime'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'draft': value['draft'],
        'versionAuthor': value['versionAuthor'],
        'versionContentPreview': ReportVersionContentPreviewDTOToJSON(value['versionContentPreview']),
        'versionDescription': value['versionDescription'],
        'versionName': value['versionName'],
    };
}

