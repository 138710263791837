import React from 'react';
import { noop } from 'lodash';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { Padding } from '../../modules/layout';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow } from '../layout-row/LayoutRow';

import { HorizontalTabsContext } from './HorizontalTabsContext';
import { HorizontalTabsItem } from './HorizontalTabsItem';

import './HorizontalTabs.less';

const block = bemBlock('n-horizontal-tabs');

type HorizontalTabsProps = {
  children: React.ReactNode;
  activeTabId?: string;
  className?: string;
  onChange?: (tabName: string) => void;
  startOutlet?: React.ReactNode;
  endOutlet?: React.ReactNode;
  'data-role'?: string;
  withPadding?: Padding;
  opaque?: decorator.Opaque;
  withScrollbar?: boolean;
};

const HorizontalTabsRaw: React.FC<HorizontalTabsProps> = ({
  children,
  activeTabId = '',
  className,
  onChange = noop,
  startOutlet,
  endOutlet,
  withPadding = ['sm', 'sm', 'none', 'sm'],
  withScrollbar,
  opaque = 'white',
  'data-role': dataRole,
}) => {
  const tabIdsInOrder = React.useMemo(() => extractTabIds(children), [children]);

  const tabsContext = React.useMemo(
    () => ({ activeTabId, onChange, tabIdsInOrder }),
    [activeTabId, onChange, tabIdsInOrder],
  );

  return (
    <LayoutColumn
      withPadding={withPadding}
      className={block({
        element: 'container',
        extra: className,
        modifiers: { opaque },
      })}
      data-role={dataRole}
    >
      <HorizontalTabsContext.Provider value={tabsContext}>
        <LayoutRow spacedChildren="sm">
          {startOutlet}

          <LayoutRow
            className={block({
              element: 'tabs',
              modifiers: {
                'with-scrollbar': withScrollbar,
              },
            })}
            span="shrink"
          >
            {children}
          </LayoutRow>

          {endOutlet}
        </LayoutRow>
      </HorizontalTabsContext.Provider>

      {opaque === 'white' && <div className={block('bottom-border')} />}
    </LayoutColumn>
  );
};

export const HorizontalTabs = Object.assign(HorizontalTabsRaw, {
  Item: HorizontalTabsItem,
});

function extractTabIds(children: React.ReactNode): string[] {
  let tabIds: string[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === HorizontalTabsItem) {
        tabIds.push(child.props.tabId);
      } else if (child.props.children) {
        tabIds = tabIds.concat(extractTabIds(child.props.children));
      }
    }
  });

  return tabIds;
}
