// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as uiState from '../../modules/ui-state';
import { WithNonInterferingComponent } from '../../modules/utils';
import { GlyphName, isGlyph } from '../icon/glyphs';
import { Icon, IconProps } from '../icon/Icon';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './TabsMenuItem.less';

export type TabSize = 'sm' | 'md' | 'lg';

type TabsMenuItemOwnProps<P> = {
  disabled?: boolean;
  icon?: GlyphName | IconProps;
  label?: React.ReactNode;
  /** @ignore onChange is used internally by TabsMenu */
  onChange?: (event: React.MouseEvent, value?: P) => void;
  onClick?: (event: React.MouseEvent) => void;
  selected?: boolean;
  size?: TabSize;
  value?: P;
  vertical?: boolean;
  square?: boolean;
  radius?: 'xs' | 'sm';
};

export type TabsMenuItemProps<P extends object, Q = number> = WithNonInterferingComponent<
  P,
  TabsMenuItemOwnProps<Q>
> &
  TabsMenuItemOwnProps<Q> &
  Pick<
    LayoutRowProps<{}>,
    | 'alignSelf'
    | 'className'
    | 'selfSpacing'
    | 'span'
    | 'style'
    | 'withGutter'
    | 'withPadding'
    | 'alignItems'
    | 'justifyContent'
    | 'spacedChildren'
  >;

const block = bemBlock('n-TabsMenuItem');

/**
 * > **NOTE**:
 * > `children` property overwrites `icon` and `label`
 */
export function TabsMenuItem<P extends object, Q = number>({
  className,
  children,
  disabled,
  icon,
  label,
  selected,
  size = 'md',
  value,
  onClick,
  onChange,
  vertical,
  square,
  radius = 'xs',
  ...passProps
}: TabsMenuItemProps<P, Q>) {
  const cssClasses = block({
    extra: [
      className,
      uiState.clickableClassName(!selected && !disabled),
      uiState.disabledClassName(disabled),
      uiState.selectedClassName(selected && !disabled),
    ],
    modifiers: {
      [size]: true,
      vertical,
      square,
      radius,
    },
  });

  const handleChange = React.useCallback(
    (event) => {
      onClick && onClick(event);
      onChange && onChange(event, value);
    },
    [onClick, onChange, value],
  );

  return (
    <LayoutRow
      className={cssClasses}
      data-role="tab"
      span="auto"
      spacedChildren="sm"
      alignItems="center"
      justifyContent={vertical ? 'start' : 'center'}
      onClick={disabled ? undefined : handleChange}
      aria-disabled={disabled}
      {...passProps}
    >
      <TabMenuItemIcon icon={icon} />
      {children === undefined && <TabMenuItemLabel label={label} />}
      {children}
    </LayoutRow>
  );
}

function TabMenuItemIcon({ icon }: Pick<TabsMenuItemProps<{}>, 'icon'>) {
  if (!icon) {
    return null;
  } else if (isGlyph(icon)) {
    return <Icon className={block('icon')} glyph={icon} />;
  }

  const { className, ...rest } = icon;

  return (
    <Icon
      className={block({
        element: 'icon',
        extra: className,
      })}
      {...rest}
    />
  );
}

function TabMenuItemLabel({ label }: Pick<TabsMenuItemProps<{}>, 'label'>) {
  if (!label) {
    return null;
  }

  if (typeof label === 'string') {
    return <span>{label}</span>;
  }

  return <>{label}</>;
}
