import React from 'react';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import { Icon } from '../icon/Icon';
import { LayoutRow } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';

import { useHorizontalTabsContext } from './HorizontalTabsContext';

import './HorizontalTabsItem.less';

const block = bemBlock('n-horizontal-tabs-item');

type HorizontalTabsItemProps = {
  disabled?: boolean;
  tabId: string;
  'data-role'?: string;
  glyph?: GlyphName;
  variant?: 'basic' | 'full-content';
  opaque?: decorator.Opaque;
};

export const HorizontalTabsItem: React.FC<HorizontalTabsItemProps> = ({
  disabled,
  tabId,
  'data-role': dataRole,
  glyph,
  children,
  variant = 'basic',
  opaque = 'white',
}) => {
  const { activeTabId, onChange, tabIdsInOrder } = useHorizontalTabsContext();

  const active = activeTabId === tabId;

  const rightSiblingOfActiveTab = React.useMemo(() => {
    const activeTabIndex = tabIdsInOrder.indexOf(activeTabId);
    const currentTabIndex = tabIdsInOrder.indexOf(tabId);

    return currentTabIndex - activeTabIndex === 1;
  }, [activeTabId, tabId, tabIdsInOrder]);

  const firstTab = React.useMemo(() => {
    const currentTabIndex = tabIdsInOrder.indexOf(tabId);
    return currentTabIndex === 0;
  }, [tabId, tabIdsInOrder]);

  const handleChange = React.useCallback(() => {
    if (active) {
      return;
    }

    onChange?.(tabId);
  }, [active, onChange, tabId]);

  return (
    <button
      data-role={dataRole}
      disabled={disabled}
      className={block({
        modifiers: {
          active,
          'right-sibling-of-active-tab': rightSiblingOfActiveTab,
          'first-tab': firstTab,
          opaque,
        },
      })}
      onClick={handleChange}
      data-tab-item={tabId}
      data-selected-tab={active}
    >
      <LayoutRow spacedChildren="sm" alignContent="center" alignItems="center" overflow="hidden">
        {glyph && (
          <Icon
            glyph={glyph}
            size="1x"
            className={block({ element: 'icon', modifiers: { active } })}
          />
        )}

        {children && (active || variant === 'full-content') && (
          <Text size="sm" fontWeight="semibold" color="text" className={block('text')}>
            {children}
          </Text>
        )}
      </LayoutRow>
    </button>
  );
};
