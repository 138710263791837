/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeNameFilterDTO } from './AttributeNameFilterDTO';
import {
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
} from './AttributeNameFilterDTO';
import type { NextPageDTO } from './NextPageDTO';
import {
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
} from './NextPageDTO';
import type { AttributeFilterDTO } from './AttributeFilterDTO';
import {
    AttributeFilterDTOFromJSON,
    AttributeFilterDTOFromJSONTyped,
    AttributeFilterDTOToJSON,
} from './AttributeFilterDTO';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsBodyDTO
 */
export interface QueryAttributeDefinitionsBodyDTO {
    /**
     * Filter by attribute (match any), if null no type or property value filtering is applied
     * @type {Array<AttributeFilterDTO>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeFilter?: Array<AttributeFilterDTO>;
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
    /**
     * Filter by attribute name with regex, if null no name filtering is applied; deprecated, use attributeNameFilter instead; if attributeNameFilter is set, this field is ignored
     * @type {string}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeNameRegex?: string;
    /**
     * Filter by experiment id, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    experimentIdsFilter?: Array<string>;
    /**
     * Filter by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    experimentNamesFilter?: Array<string>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    nextPage?: NextPageDTO;
}

/**
 * Check if a given object implements the QueryAttributeDefinitionsBodyDTO interface.
 */
export function instanceOfQueryAttributeDefinitionsBodyDTO(value: object): value is QueryAttributeDefinitionsBodyDTO {
    return true;
}

export function QueryAttributeDefinitionsBodyDTOFromJSON(json: any): QueryAttributeDefinitionsBodyDTO {
    return QueryAttributeDefinitionsBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsBodyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeFilter': json['attributeFilter'] == null ? undefined : ((json['attributeFilter'] as Array<any>).map(AttributeFilterDTOFromJSON)),
        'attributeNameFilter': json['attributeNameFilter'] == null ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
        'attributeNameRegex': json['attributeNameRegex'] == null ? undefined : json['attributeNameRegex'],
        'experimentIdsFilter': json['experimentIdsFilter'] == null ? undefined : json['experimentIdsFilter'],
        'experimentNamesFilter': json['experimentNamesFilter'] == null ? undefined : json['experimentNamesFilter'],
        'nextPage': json['nextPage'] == null ? undefined : NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributeDefinitionsBodyDTOToJSON(value?: QueryAttributeDefinitionsBodyDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeFilter': value['attributeFilter'] == null ? undefined : ((value['attributeFilter'] as Array<any>).map(AttributeFilterDTOToJSON)),
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value['attributeNameFilter']),
        'attributeNameRegex': value['attributeNameRegex'],
        'experimentIdsFilter': value['experimentIdsFilter'],
        'experimentNamesFilter': value['experimentNamesFilter'],
        'nextPage': NextPageDTOToJSON(value['nextPage']),
    };
}

