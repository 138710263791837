import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import './EnforceLtr.less';

const block = bemBlock('enforce-ltr');

export const EnforceLtr: React.FC = ({ children }) => (
  <>
    <span className={block('empty-sign')}>&lrm;</span>
    {children}
    <span className={block('empty-sign')}>&lrm;</span>
  </>
);
