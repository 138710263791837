// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

// App
import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutRow } from '../layout-row/LayoutRow';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';
import { Text } from '../text/Text';

// Module
import './OverlayView.less';

const block = bemBlock('overlay-view');

type OverlayViewProps = {
  title?: string;
  target?: Element;
  onClose?: () => void;
  isVisible: boolean;

  'data-role'?: string;
};

export const OverlayView: React.FC<OverlayViewProps> = ({
  target = document.body,
  title,
  children,
  isVisible = false,
  onClose,
}) => {
  const nodeRef = React.useRef(null);

  const [visible, setVisible] = React.useState(isVisible);

  React.useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const handleClose = React.useCallback(() => {
    if (visible) {
      return;
    }

    onClose?.();
  }, [onClose, visible]);

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={visible}
      timeout={500}
      appear
      mountOnEnter
      unmountOnExit
      classNames={block('animation')}
      nodeRef={nodeRef}
      onExited={handleClose}
    >
      <LayoutColumn elementRef={nodeRef} className={block()} data-role={'overlay-view'}>
        <LayoutRow span="auto" alignItems="center" withPadding={['md', 'lg']}>
          <Text
            className={block('title')}
            fontWeight="bold"
            size="xxl"
            title={title}
            data-role="overlay-view-title"
          >
            {title}
          </Text>
          <LayoutFill />
          {onClose && (
            <Icon data-role="close-overlay" glyph="cancel" size="lg" onClick={handleCancel} />
          )}
        </LayoutRow>
        <LayoutSeparator />
        <LayoutColumn overflow="auto">{children}</LayoutColumn>
      </LayoutColumn>
    </CSSTransition>,
    target,
  );
};
