// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { DropdownSeparator } from '../dropdown-separator/DropdownSeparator';
import { DropdownTitle } from '../dropdown-title/DropdownTitle';
import { LayoutColumn } from '../layout-column/LayoutColumn';

import './DropdownGroup.less';

const block = bemBlock('dropdown-group');

export type DropdownGroupProps = {
  title?: string;
  'data-role'?: string;
};

export const DropdownGroup: React.FC<DropdownGroupProps> = ({
  title,
  'data-role': dataRole,
  children,
}) => {
  return (
    <LayoutColumn className={block()} data-role={dataRole}>
      <DropdownSeparator className={block('separator')} />
      {title && <DropdownTitle variant="secondary">{title}</DropdownTitle>}
      {children}
    </LayoutColumn>
  );
};
