import React from 'react';

import { bemBlock, Text, TextProps } from '@neptune/shared/venus-ui';

import { EnforceLtr } from '../../enforce-ltr/EnforceLtr';

import './MiddleEllipsisRenderer.less';

type MiddleEllipsisRendererProps = {
  text: string;
  expandable?: boolean;
  firstPart: React.ReactNode;
  secondPart: React.ReactNode;
  'data-role'?: string;
} & Pick<
  TextProps<{}>,
  'size' | 'className' | 'fontFamily' | 'fontWeight' | 'lineHeight' | 'color'
>;

const block = bemBlock('middle-ellipsis-renderer');

export const MiddleEllipsisRenderer = ({
  text,
  color,
  firstPart,
  secondPart,
  className,
  fontWeight,
  fontFamily,
  lineHeight,
  expandable,
  size,
  'data-role': dataRole,
}: MiddleEllipsisRendererProps) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  return (
    <Text
      className={block({ extra: className })}
      title={text}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      lineHeight={lineHeight}
      size={size}
      color={color}
      data-role={dataRole}
      onPointerEnter={expandable ? () => setIsHovered(true) : undefined}
      onPointerLeave={expandable ? () => setIsHovered(false) : undefined}
    >
      <div
        className={block({
          element: 'full-text',
          modifiers: { hidden: !isHovered },
        })}
      >
        {text}
      </div>

      <Text
        className={block({
          element: 'truncated-end',
          modifiers: { hidden: isHovered },
        })}
      >
        {firstPart}
      </Text>
      <Text
        className={block({
          element: 'truncated-start',
          modifiers: { hidden: isHovered },
        })}
      >
        <EnforceLtr>{secondPart}</EnforceLtr>
      </Text>
    </Text>
  );
};
