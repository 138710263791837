/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GitInfoDTO } from './GitInfoDTO';
import {
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
} from './GitInfoDTO';
import type { ExperimentTypeDTO } from './ExperimentTypeDTO';
import {
    ExperimentTypeDTOFromJSON,
    ExperimentTypeDTOFromJSONTyped,
    ExperimentTypeDTOToJSON,
} from './ExperimentTypeDTO';

/**
 * 
 * @export
 * @interface ExperimentCreationParams
 */
export interface ExperimentCreationParams {
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    checkpointId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    customId?: string;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof ExperimentCreationParams
     */
    gitInfo?: GitInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    notebookId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    projectIdentifier?: string;
    /**
     * 
     * @type {ExperimentTypeDTO}
     * @memberof ExperimentCreationParams
     */
    type?: ExperimentTypeDTO;
}



/**
 * Check if a given object implements the ExperimentCreationParams interface.
 */
export function instanceOfExperimentCreationParams(value: object): value is ExperimentCreationParams {
    return true;
}

export function ExperimentCreationParamsFromJSON(json: any): ExperimentCreationParams {
    return ExperimentCreationParamsFromJSONTyped(json, false);
}

export function ExperimentCreationParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentCreationParams {
    if (json == null) {
        return json;
    }
    return {
        
        'checkpointId': json['checkpointId'] == null ? undefined : json['checkpointId'],
        'customId': json['customId'] == null ? undefined : json['customId'],
        'gitInfo': json['gitInfo'] == null ? undefined : GitInfoDTOFromJSON(json['gitInfo']),
        'key': json['key'] == null ? undefined : json['key'],
        'notebookId': json['notebookId'] == null ? undefined : json['notebookId'],
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'projectIdentifier': json['projectIdentifier'] == null ? undefined : json['projectIdentifier'],
        'type': json['type'] == null ? undefined : ExperimentTypeDTOFromJSON(json['type']),
    };
}

export function ExperimentCreationParamsToJSON(value?: ExperimentCreationParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkpointId': value['checkpointId'],
        'customId': value['customId'],
        'gitInfo': GitInfoDTOToJSON(value['gitInfo']),
        'key': value['key'],
        'notebookId': value['notebookId'],
        'parentId': value['parentId'],
        'projectId': value['projectId'],
        'projectIdentifier': value['projectIdentifier'],
        'type': ExperimentTypeDTOToJSON(value['type']),
    };
}

