import cookie from 'js-cookie';

import { heap } from 'analytics/heap';
import * as userflow from 'analytics/userflow';

import config from 'config';

type NeptuneTrackEventProperties = Record<string, string | number>;

function trackError(eventName: string, properties?: NeptuneTrackEventProperties): void {
  try {
    heap.track(eventName, properties);
  } catch (e) {}
  // do not track in Intercom
}

function track(eventName: string, properties?: NeptuneTrackEventProperties) {
  try {
    heap.track(eventName, properties);
  } catch (e) {}

  try {
    if (typeof window.Intercom === 'function') {
      window.Intercom('trackEvent', eventName, properties);
    }
  } catch (e) {}

  userflow.track(eventName, properties);
}

function setProperty(key: string, value: string | number) {
  setProperties({ [key]: value });
}

function setProperties(properties: NeptuneTrackEventProperties) {
  try {
    heap.addUserProperties(properties);
  } catch (e) {}

  try {
    if (typeof window.Intercom === 'function') {
      window.Intercom('update', properties);
    }
  } catch (e) {}

  userflow.updateUser(properties);
}

function logout(): void {
  try {
    heap.resetIdentity();
  } catch (e) {}

  try {
    if (typeof window.Intercom === 'function') {
      const options = {
        domain: config.neptuneCookieDomain,
        path: '/',
      };
      window.Intercom('hide');
      window.Intercom('shutdown');
      cookie.remove('neptune-uid', options);
      delete window.intercomSettings;
    }
  } catch (e) {}

  userflow.reset();
}

function startProductTour(tourId: number) {
  try {
    window.Intercom('startTour', tourId);
  } catch (e) {}

  if (config.appEnv !== 'dist') {
    // eslint-disable-next-line no-console
    console.warn(`Intercom tour ${tourId} should have started right now`);
  }
}

export const neptune = {
  logout,
  trackError,
  track,
  setProperty,
  setProperties,
  startProductTour,
};
