/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilterQueryAttributeDefinitionsDTO } from './FilterQueryAttributeDefinitionsDTO';
import {
    FilterQueryAttributeDefinitionsDTOFromJSON,
    FilterQueryAttributeDefinitionsDTOFromJSONTyped,
    FilterQueryAttributeDefinitionsDTOToJSON,
} from './FilterQueryAttributeDefinitionsDTO';
import type { PriorityQueryAttributeDefinitionsDTO } from './PriorityQueryAttributeDefinitionsDTO';
import {
    PriorityQueryAttributeDefinitionsDTOFromJSON,
    PriorityQueryAttributeDefinitionsDTOFromJSONTyped,
    PriorityQueryAttributeDefinitionsDTOToJSON,
} from './PriorityQueryAttributeDefinitionsDTO';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsPrioritizedBodyDTO
 */
export interface QueryAttributeDefinitionsPrioritizedBodyDTO {
    /**
     * 
     * @type {FilterQueryAttributeDefinitionsDTO}
     * @memberof QueryAttributeDefinitionsPrioritizedBodyDTO
     */
    filterQueryAttributeDefinitionsDTO: FilterQueryAttributeDefinitionsDTO;
    /**
     * 
     * @type {number}
     * @memberof QueryAttributeDefinitionsPrioritizedBodyDTO
     */
    limit?: number;
    /**
     * 
     * @type {PriorityQueryAttributeDefinitionsDTO}
     * @memberof QueryAttributeDefinitionsPrioritizedBodyDTO
     */
    priorityQueryAttributeDefinitionsDTO?: PriorityQueryAttributeDefinitionsDTO;
}

/**
 * Check if a given object implements the QueryAttributeDefinitionsPrioritizedBodyDTO interface.
 */
export function instanceOfQueryAttributeDefinitionsPrioritizedBodyDTO(value: object): value is QueryAttributeDefinitionsPrioritizedBodyDTO {
    if (!('filterQueryAttributeDefinitionsDTO' in value) || value['filterQueryAttributeDefinitionsDTO'] === undefined) return false;
    return true;
}

export function QueryAttributeDefinitionsPrioritizedBodyDTOFromJSON(json: any): QueryAttributeDefinitionsPrioritizedBodyDTO {
    return QueryAttributeDefinitionsPrioritizedBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsPrioritizedBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsPrioritizedBodyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'filterQueryAttributeDefinitionsDTO': FilterQueryAttributeDefinitionsDTOFromJSON(json['filterQueryAttributeDefinitionsDTO']),
        'limit': json['limit'] == null ? undefined : json['limit'],
        'priorityQueryAttributeDefinitionsDTO': json['priorityQueryAttributeDefinitionsDTO'] == null ? undefined : PriorityQueryAttributeDefinitionsDTOFromJSON(json['priorityQueryAttributeDefinitionsDTO']),
    };
}

export function QueryAttributeDefinitionsPrioritizedBodyDTOToJSON(value?: QueryAttributeDefinitionsPrioritizedBodyDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filterQueryAttributeDefinitionsDTO': FilterQueryAttributeDefinitionsDTOToJSON(value['filterQueryAttributeDefinitionsDTO']),
        'limit': value['limit'],
        'priorityQueryAttributeDefinitionsDTO': PriorityQueryAttributeDefinitionsDTOToJSON(value['priorityQueryAttributeDefinitionsDTO']),
    };
}

