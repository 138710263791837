/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriorityQueryAttributeDefinitionsDTO
 */
export interface PriorityQueryAttributeDefinitionsDTO {
    /**
     * Prioritize by experiment id, if null no prioritization is applied
     * @type {Array<string>}
     * @memberof PriorityQueryAttributeDefinitionsDTO
     */
    experimentIds?: Array<string>;
    /**
     * Prioritize by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof PriorityQueryAttributeDefinitionsDTO
     */
    experimentNamesFilter?: Array<string>;
}

/**
 * Check if a given object implements the PriorityQueryAttributeDefinitionsDTO interface.
 */
export function instanceOfPriorityQueryAttributeDefinitionsDTO(value: object): value is PriorityQueryAttributeDefinitionsDTO {
    return true;
}

export function PriorityQueryAttributeDefinitionsDTOFromJSON(json: any): PriorityQueryAttributeDefinitionsDTO {
    return PriorityQueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function PriorityQueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriorityQueryAttributeDefinitionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'experimentIds': json['experimentIds'] == null ? undefined : json['experimentIds'],
        'experimentNamesFilter': json['experimentNamesFilter'] == null ? undefined : json['experimentNamesFilter'],
    };
}

export function PriorityQueryAttributeDefinitionsDTOToJSON(value?: PriorityQueryAttributeDefinitionsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'experimentIds': value['experimentIds'],
        'experimentNamesFilter': value['experimentNamesFilter'],
    };
}

