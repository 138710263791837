// Libs
import { startCase } from 'lodash';

import { Namespaces } from 'domain/common/namespaces';
import { isInSystemNamespace } from 'domain/common/utils';
import { KnownAttributes } from 'domain/experiment/attribute/known-attributes';

// Module
import type {
  Attribute,
  AttributeByType,
  AttributeType,
  BaseAttribute,
  BooleanAttribute,
  DateTimeAttribute,
  ExperimentState,
  ExperimentStateAttribute,
  FileAttribute,
  FileSetAttribute,
  FloatAttribute,
  FloatSeriesAttribute,
  GitRefAttribute,
  ImageSeriesAttribute,
  IntegerAttribute,
  StringAttribute,
  StringSeriesAttribute,
  StringSetAttribute,
} from './attribute-model';

export function isBooleanAttribute(param: BaseAttribute): param is BooleanAttribute {
  return param.attributeType === 'bool';
}

export function isDateTimeAttribute(param: BaseAttribute): param is DateTimeAttribute {
  return param.attributeType === 'datetime';
}

export function isExperimentStateAttribute(
  param: BaseAttribute,
): param is ExperimentStateAttribute {
  return param.attributeType === 'experimentState';
}

export function isFileAttribute(param: BaseAttribute): param is FileAttribute {
  return param.attributeType === 'file';
}

export function isFileSetAttribute(param: BaseAttribute): param is FileSetAttribute {
  return param.attributeType === 'fileSet';
}

export function isFloatAttribute(param: BaseAttribute): param is FloatAttribute {
  return param.attributeType === 'float';
}

export function isFloatSeriesAttribute(param: BaseAttribute): param is FloatSeriesAttribute {
  return param.attributeType === 'floatSeries';
}

export function isGitRefAttribute(param: BaseAttribute): param is GitRefAttribute {
  return param.attributeType === 'gitRef';
}

export function isImageSeriesAttribute(param: BaseAttribute): param is ImageSeriesAttribute {
  return param.attributeType === 'imageSeries';
}

export function isIntegerAttribute(param: BaseAttribute): param is IntegerAttribute {
  return param.attributeType === 'int';
}

export function isStringAttribute(param: BaseAttribute): param is StringAttribute {
  return param.attributeType === 'string';
}

export function isStringSeriesAttribute(param: BaseAttribute): param is StringSeriesAttribute {
  return param.attributeType === 'stringSeries';
}

export function isStringSetAttribute(param: BaseAttribute): param is StringSetAttribute {
  return param.attributeType === 'stringSet';
}

export function getReadableAttributeName(attributeName: string): string {
  if (isInSystemNamespace(attributeName)) {
    return startCase(attributeName.replace(`${Namespaces.System}/`, ''));
  }

  return attributeName;
}

export function getAttribute<T extends AttributeType>(
  attributes: Attribute[],
  name: string,
  type: T,
): AttributeByType<T> | undefined {
  return attributes.find(
    (attribute) => attribute.attributeName === name && attribute.attributeType === type,
  ) as AttributeByType<T> | undefined;
}

export function getIdFromAttributes(attributes: Attribute[]): string | undefined {
  return getAttribute(attributes, KnownAttributes.Id, 'string')?.value;
}

export function getNameFromAttributes(attributes: Attribute[]): string | undefined {
  return getAttribute(attributes, KnownAttributes.Name, 'string')?.value;
}

export function getModelIdFromAttributes(attributes: Attribute[]): string | undefined {
  return getAttribute(attributes, KnownAttributes.ModelId, 'string')?.value;
}

export function getFailedFromAttributes(attributes: Attribute[]): boolean | undefined {
  return getAttribute(attributes, KnownAttributes.Failed, 'bool')?.value;
}

export function getGitDiffFromAttributes(attributes: Attribute[]): FileAttribute | undefined {
  return getAttribute(attributes, KnownAttributes.GitDiff, 'file');
}

export function getRunIdentificationKeyFromAttributes(attributes: Attribute[]): string | undefined {
  return getNameFromAttributes(attributes) || getIdFromAttributes(attributes);
}

export function getGitUpstreamDiffFromAttributes(
  attributes: Attribute[],
): FileAttribute | undefined {
  return attributes
    .filter(isFileAttribute)
    .find(({ attributeName }) => attributeName.startsWith(KnownAttributes.GitUpstreamDiffPrefix));
}

export function getTagsFromAttributes(attributes: Attribute[]): string[] | undefined {
  return getAttribute(attributes, KnownAttributes.Tags, 'stringSet')?.values;
}

export function getAttributeValue<T extends AttributeType, SUB extends string = string>(
  attribute: Attribute | undefined,
  type: T,
  subProperty?: SUB,
): Date | ExperimentState | boolean | number | undefined | string | string[] {
  if (!attribute || attribute.attributeType !== type) {
    return undefined;
  }

  if (isBooleanAttribute(attribute)) {
    return attribute.value;
  }

  if (isDateTimeAttribute(attribute)) {
    return attribute.value;
  }

  if (isExperimentStateAttribute(attribute)) {
    return attribute.value;
  }

  if (isFileAttribute(attribute)) {
    return attribute.name;
  }

  if (isFileSetAttribute(attribute)) {
    return attribute.size;
  }

  if (isFloatAttribute(attribute)) {
    return attribute.value;
  }

  if (isFloatSeriesAttribute(attribute)) {
    switch (subProperty) {
      case 'last':
        return attribute.last;
      case 'average':
        return attribute.average;
      case 'max':
        return attribute.max;
      case 'min':
        return attribute.min;
      case 'variance':
        return attribute.variance;
      default:
        return attribute.last;
    }
  }

  if (isGitRefAttribute(attribute)) {
    return attribute.commit.commitId;
  }

  if (isImageSeriesAttribute(attribute)) {
    return attribute.channelId;
  }

  if (isIntegerAttribute(attribute)) {
    return attribute.value;
  }

  if (isStringAttribute(attribute)) {
    return attribute.value;
  }

  if (isStringSeriesAttribute(attribute)) {
    return attribute.last;
  }

  if (isStringSetAttribute(attribute)) {
    return attribute.values;
  }

  return undefined;
}

export function createFloatSeriesAttributeStub(attributeName: string): FloatSeriesAttribute {
  return {
    attributeName,
    attributeType: 'floatSeries',
    config: {},
  };
}
