/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeNameFilterDTO } from './AttributeNameFilterDTO';
import {
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
} from './AttributeNameFilterDTO';
import type { NextPageDTO } from './NextPageDTO';
import {
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
} from './NextPageDTO';

/**
 * 
 * @export
 * @interface QueryAttributesBodyDTO
 */
export interface QueryAttributesBodyDTO {
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributesBodyDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
    /**
     * Filter by attribute name, if null all attributes are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    attributeNamesFilter?: Array<string>;
    /**
     * Filter by experiment id, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    experimentIdsFilter?: Array<string>;
    /**
     * Filter by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    experimentNamesFilter?: Array<string>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributesBodyDTO
     */
    nextPage?: NextPageDTO;
}

/**
 * Check if a given object implements the QueryAttributesBodyDTO interface.
 */
export function instanceOfQueryAttributesBodyDTO(value: object): value is QueryAttributesBodyDTO {
    return true;
}

export function QueryAttributesBodyDTOFromJSON(json: any): QueryAttributesBodyDTO {
    return QueryAttributesBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributesBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributesBodyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeNameFilter': json['attributeNameFilter'] == null ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
        'attributeNamesFilter': json['attributeNamesFilter'] == null ? undefined : json['attributeNamesFilter'],
        'experimentIdsFilter': json['experimentIdsFilter'] == null ? undefined : json['experimentIdsFilter'],
        'experimentNamesFilter': json['experimentNamesFilter'] == null ? undefined : json['experimentNamesFilter'],
        'nextPage': json['nextPage'] == null ? undefined : NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributesBodyDTOToJSON(value?: QueryAttributesBodyDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value['attributeNameFilter']),
        'attributeNamesFilter': value['attributeNamesFilter'],
        'experimentIdsFilter': value['experimentIdsFilter'],
        'experimentNamesFilter': value['experimentNamesFilter'],
        'nextPage': NextPageDTOToJSON(value['nextPage']),
    };
}

