/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeDTO } from './AttributeDTO';
import {
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
} from './AttributeDTO';

/**
 * 
 * @export
 * @interface QueryAttributesExperimentResultDTO
 */
export interface QueryAttributesExperimentResultDTO {
    /**
     * 
     * @type {Array<AttributeDTO>}
     * @memberof QueryAttributesExperimentResultDTO
     */
    attributes: Array<AttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof QueryAttributesExperimentResultDTO
     */
    experimentId: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAttributesExperimentResultDTO
     */
    experimentShortId: string;
}

/**
 * Check if a given object implements the QueryAttributesExperimentResultDTO interface.
 */
export function instanceOfQueryAttributesExperimentResultDTO(value: object): value is QueryAttributesExperimentResultDTO {
    if (!('attributes' in value) || value['attributes'] === undefined) return false;
    if (!('experimentId' in value) || value['experimentId'] === undefined) return false;
    if (!('experimentShortId' in value) || value['experimentShortId'] === undefined) return false;
    return true;
}

export function QueryAttributesExperimentResultDTOFromJSON(json: any): QueryAttributesExperimentResultDTO {
    return QueryAttributesExperimentResultDTOFromJSONTyped(json, false);
}

export function QueryAttributesExperimentResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributesExperimentResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
        'experimentId': json['experimentId'],
        'experimentShortId': json['experimentShortId'],
    };
}

export function QueryAttributesExperimentResultDTOToJSON(value?: QueryAttributesExperimentResultDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributes': ((value['attributes'] as Array<any>).map(AttributeDTOToJSON)),
        'experimentId': value['experimentId'],
        'experimentShortId': value['experimentShortId'],
    };
}

