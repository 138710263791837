// Libs
import React, { ButtonHTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';
import * as layout from '../../modules/layout';
import * as uiState from '../../modules/ui-state';
import { createComponent, WithNonInterferingComponent } from '../../modules/utils';
import { makeRefProp } from '../make-ref-prop-util';

// Module
import './Button.less';

const block = bemBlock('n-Button');
// TODO @new-ui remove transparent
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'basic'
  | 'destructive'
  | 'transparent'
  | 'secondary-text';
export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | 'w-full';

export type ButtonProps<P extends object> = WithNonInterferingComponent<P, ButtonOwnProps> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    elementRef?: React.Ref<any>;
    pressed?: boolean;
    size?: ButtonSize;
    /** Recommended for buttons with Icon only. */
    square?: boolean;
    /** If `square` is true, `withPadding` is ignored */
    withPadding?: layout.Padding;
    variant?: ButtonVariant;
  };

type ButtonOwnProps = {
  className: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
};

export function Button<P extends object>({
  className,
  component,
  disabled,
  elementRef,
  pressed,
  size = 'md',
  square,
  type = 'button',
  withPadding,
  variant = 'primary',
  ...passProps
}: ButtonProps<P>): React.ReactElement {
  const ownProps: ButtonOwnProps = {
    className: block({
      modifiers: {
        size,
        variant,
        square,
      },
      extra: [
        className,
        layout.withPaddingClassName(square ? 'none' : withPadding ?? getDefaultPaddings(size)),
        uiState.disabledClassName(disabled),
        uiState.pressedClassName(pressed),
      ],
    }),
    disabled,
    type,
  };
  const tag = component || 'button';
  const ref = makeRefProp(tag, elementRef);

  return createComponent(tag, {
    ...ownProps,
    ...passProps,
    ...ref,
  });
}

function getDefaultPaddings(size: ButtonSize): layout.Padding {
  if (size === 'sm') {
    return ['none', 'xs'];
  }

  if (size === 'md') {
    return ['none', 'sm'];
  }

  return ['none', 'md'];
}
