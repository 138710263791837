// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { HeaderTitle } from '../header-title/HeaderTitle';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';

// Module
import './Header.less';

const block = bemBlock('header');

type HeaderVariant = 'large' | 'medium' | 'small';

type HeaderProps = {
  title?: string;
  variant: HeaderVariant;
} & LayoutColumnProps<{}>;

const paddingPropsForVariant: Record<HeaderVariant, Partial<LayoutColumnProps<{}>>> = {
  large: { spacedChildren: 'md', withPadding: ['xl', 'xl', 'lg', 'xl'] },
  medium: { spacedChildren: 'md', withPadding: ['lg', 'lg', 'md', 'lg'] },
  small: { spacedChildren: 'md', withPadding: 'md' },
};

export class Header extends React.Component<HeaderProps> {
  public static defaultProps = {
    component: 'header',
    span: 'auto',
  };

  public static Title = HeaderTitle;

  render() {
    const { className, variant, ...restProps } = this.props;

    return (
      <LayoutColumn
        className={block({ extra: [className] })}
        {...paddingPropsForVariant[variant]}
        {...restProps}
      />
    );
  }
}
